import React, { Component } from 'react'

import Output from "./Output";
import SearchEngine from "./engine/SearchEngine";
import Mapping from "./engine/Mapping";
import Keyboard from "./input/Keyboard";
import Cookiesky from "./cookies/Cookiesky"

import { faSearch } from '@fortawesome/free-solid-svg-icons' 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


class SearcherSmart extends Component {
    constructor(props) {
        super(props);

         this.state = {
            error: null,
            isLoaded: false,                // ci su nacitane data, ak su tak sa tato premenna zmeni na true
            searchWord: '',                 // vyhladavane slovo v inpute
            selectedItem: -1,               // oznacena polozka klavesnicou
            items: [],                      // stiahnute data             
            bestitems: [],                  // polozky, ktore sa zobrazuju
            cookiesitems: [],
            visibleOutputs: false,          // viditelnost pre navrhy (output), ci je vidiet output popUp
            mouseOnOutputs: true,           // ci je kurzor na popUpe lebo ak je nereaguje vstup z klavesnice
            url: {
                // url odkial sa ma nacitavat konfigurak 
                urlPreNatiahnutieDat: (window.location.hostname == "localhost") ? 'ciscoskolenia.eu'  : window.location.hostname,
                protokol: "https://"
            }
        }; 

        //toto robime preto aby vo funkciach, ktore sa nacitavaju z HTML na click, zmenu a pod. sme mali dostupne globalne premenne komponentu
        this.aktivaciaStiahnutiaDat = this.aktivaciaStiahnutiaDat.bind(this);
        this.zmenaVyhladavanehoSlova = this.zmenaVyhladavanehoSlova.bind(this);
        this.onMouseDown = this.onMouseDown.bind(this);
        this.stlacenieKlavesyVInpute = this.stlacenieKlavesyVInpute.bind(this);
        //this.kliknutieNaPolozku = this.kliknutieNaPolozku.bind(this);

        let plcholder;
        let lng = document.getElementById("lit-cs-lang4s").innerHTML
        if (lng == "cz") {
            this.plcholder = "co vás zajímá?";
        } else {
            this.plcholder = "čo by ste radi vedeli?";
        }
    }

    /**
     * tato metoda sa vola ihned po tom, ako je tento komponent pridany do DOM. 
     */
    componentDidMount() {
        document.addEventListener('mousedown', this.onMouseDown);

       // document.getElementById("fs-search-ikonka-inputu").setAttribute("style", "color: black !important;margin: 5; position: absolute; right: 0;");
    }     
    
    /**
     * tato metoda sa vola pri kazdom stlaceni mysi, ak sa klikne mimo inputu tak sa navrhy schovaju
     * @param {*} event 
     */
    onMouseDown(event) {
        //ak sa klikni lavym tlacidlom
        if (event.button === 0) {
            //ak sa klikne na input alebo vysledky tak sa zobrazia posledne vysledky (ak ziadne neboli tak sa nezobrazia)
            if (event.target.id ==='fs-search-input-vyhladavania' || event.target.className ==='fs-search-vysledky-riadok list-group-item'
            || event.target.className ==='fs-search-vysledky-ticker' || event.target.className ==='fs-search-vysledky-nazov'
            || event.target.className ==='fs-search-vysledky-skupina' || event.target.className ==='fs-search-vysledky-typ') {
                this.setState({
                    visibleOutputs: true
                })
            //ak sa klikne mimo inputu alebo vysledkov tak sa zavru vysledky vyhladavania
            } else {
                this.setState({
                    visibleOutputs: false
                })
            }
            
            if (event.target.id ==='fs-search-input-vyhladavania' && this.state.searchWord == '' && this.state.isLoaded) {
                this.nacitanieDefaultnychHodnotNaZobrazenie();
            }
        }
    }

    /**
     * tato metoda sa vykona pri prvom kliknuti na input vyhladavania a stiahne vsetky potrebne data
     */
    aktivaciaStiahnutiaDat() {
        if(this.state.isLoaded == false){
            this.stiahniData();
            
            //console.log(this.state)
        }
    }

   /**
    * tato metoda sa vykona pri zmene hodnoty inputu vyhladavania
    * @param {*} e 
    */
    zmenaVyhladavanehoSlova(e) {
        var zadanaHodnota = e.target.value;
        //osetrenie na nacitanie dat
        if (this.state.isLoaded) {
            //osetrenie na diakritiku, blblo to pri znakoch
            if(this.state.items.konfig[0].ignorovatDiakritiu) {
                try {
                    zadanaHodnota = e.target.value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                } catch (error) {
                    zadanaHodnota = e.target.value
                }
            }

            //osetrenie na tieto chary, pretoze regex ich nebral
            if(zadanaHodnota.indexOf(String.fromCharCode(92)) == -1 && zadanaHodnota.indexOf("/") == -1 && zadanaHodnota.indexOf("*") == -1 && zadanaHodnota.indexOf("+") == -1 && 
            zadanaHodnota.indexOf("(") == -1 && zadanaHodnota.indexOf(")") == -1 && zadanaHodnota.indexOf("[") == -1 && zadanaHodnota.indexOf("]") == -1){
                this.setState({
                    searchWord: zadanaHodnota
                }, () => {
                    if(this.state.isLoaded == true){
                        this.setState({
                            visibleOutputs: true,
                            cookiesitems: [],
                            bestitems: Mapping.namapujNajlepsiePolozky(SearchEngine.pridanieNajlepsichTickerov(this.state))
                        }, () => {
                            console.log(this.state)
                        })
                        ;
                    }
                   }
                );
            }
            
        }
    }

    /**
     * Tato metoda sa vykona pri stlaceni klavesy v inpute
     * reaguje ale iba na sipky alebo enter
     * @param {*} e 
     */
    stlacenieKlavesyVInpute(e) {
        //osetrenie na nacitanie dat
        if (this.state.isLoaded) {
            if(this.state.mouseOnOutputs && this.state.items.konfig[0].ovladanieKlavesnicou) {
                this.setState({
                    selectedItem: Keyboard.zaznamenajStlacenieKlavesy(this.state, e)
                })
            }
        }
    }

    /**
     * tato metoda stiahne vsetky data z WS
     */
    stiahniData() {         
        let today = new Date().toISOString().slice(0, 10);
        let lang = document.getElementById("lit-cs-lang4s").innerHTML;
        lang = "&lang="+lang;
        fetch(this.state.url.protokol + this.state.url.urlPreNatiahnutieDat + '/engine/ws/vyhladavac/index.php?t=' + today+lang)
           .then(res => res.json())
           .then(
               (result) => {
                   for (let index = 0; index < result.polozky.length; index++) {
                        result.polozky[index].kodBezLomitka = result.polozky[index].kod.replace("/", "");
                       
                   }
                   this.setState({
                        isLoaded: true,
                        visibleOutputs: true,
                        items: result
                   }, () => {
                        this.nacitanieDefaultnychHodnotNaZobrazenie();
                        this.zapnutieEventListeneraNaPrechodMysiNaVysledky();
                        
                   })
            
               },
               (error) => {
                   this.setState({
                        isLoaded: false,
                        error: true
                   });
               }
           )
    }


    nacitanieDefaultnychHodnotNaZobrazenie() {
        this.setState({
            cookiesitems: Mapping.namapujPolozkyZCookies(Cookiesky.nacitajCookies(this.state))
       }, () => {
            this.setState({
                bestitems: Mapping.namapujNajlepsiePolozky(SearchEngine.nastavDefaultneTickery(this.state)),
            }, ()=> {
                console.log(this.state)
            });
       })
    }

    /**
     * Tato metoda zapina EventListenery pri nacitani dat
     * resetuje vybranu polozku pri prechode mysi na navrhy
     * taktiez vypina moznost sipok pri mysi na navrhoch
     */
    zapnutieEventListeneraNaPrechodMysiNaVysledky() {
        const divEl = document.querySelector('#fs-seaerch-divko-vysledkov');
        divEl.addEventListener('mouseenter', () => {
            this.setState({
                selectedItem: -1,
                mouseOnOutputs: false 
            })
        });

        divEl.addEventListener('mouseout', () => {
            this.setState({
                selectedItem: -1,
                mouseOnOutputs: true
            })
        });

        divEl.addEventListener('mouseover', () => {
            this.setState({
                selectedItem: -1,
                mouseOnOutputs: false 
            })
        });
    }

    /**
     * Tato metoda vykresli htmlko
     */
    render() {
        return (
            <div>
                <div className="fs-search-button-wrapper input-group fs-search-pozadie"
                    ref={(el) => {if (el) { }}}>
                    <input id="fs-search-input-vyhladavania" className="fs-search-button form-control" type="text" aria-describedby="basic-addon1"
                    onClick={this.aktivaciaStiahnutiaDat} onChange={this.zmenaVyhladavanehoSlova} onKeyDown={this.stlacenieKlavesyVInpute} autoComplete="off" 
                    value={this.state.searchWord} ref={(el) => {if (el) { }}}
                    placeholder={this.plcholder}></input>     
                </div>
                <div id="fs-seaerch-divko-vysledkov">
                    {this.state.visibleOutputs && this.state.bestitems.length != 0 ?
                        <Output bestTickery={this.state.bestitems} vybranyTicker={this.state.selectedItem} citatCookies={this.state.items.konfig[0].pouzivatCookies}
                                cookiesTickery={this.state.cookiesitems}/> : ''}
                </div>
            </div>
            
        )
    }
}
export default SearcherSmart