import React, { Component } from 'react'
import Cookiesky from '../cookies/Cookiesky'

export default class Keyboard extends Component {

    /**
     * Tato metoda sa spusti pri stlaceni klavesy v inpute
     * Rozhoduje, co sa vykona pri akej klavese
     * @param {*} state 
     * @param {*} e 
     */
    static zaznamenajStlacenieKlavesy(state, e) {
        e = e || window.event;
        
        // Stlacenie sipky dole
        if (e.code == "ArrowDown") {
            state.selectedItem = this.stlacenieSipkyDole(state);
        }
        // Stlacenie sipky hore
        else if (e.code == "ArrowUp") {
            state.selectedItem = this.stlacenieSipkyHore(state);
        } 
        // Stlacenie Enteru
        else if (Number(e.keyCode) == 13) {
            this.stlacenieEnteru(state)

        // Stlacenie ostatnych klaves (vyresetuje sa vybrany ticker)
        } else {
            state.selectedItem = -1;
        }

        return state.selectedItem;
    }

    /**
     * Tato metoda sa vykona pri stlaceni klavesy ↓
     * @param {*} state 
     */
    static stlacenieSipkyDole(state) {
        if (state.selectedItem < state.items.konfig[0].pocetZobrazovanychPoloziek && state.selectedItem < (state.bestitems.length - 1 + state.cookiesitems.length)) {
            state.selectedItem++;
        } else if (state.selectedItem == (state.bestitems.length - 1 + state.cookiesitems.length)) {
            state.selectedItem = 0;
        }

        return state.selectedItem;
    }

    /**
     * Tato metoda sa vykona pri stlaceni klavesy ↑
     * @param {*} state 
     */
    static stlacenieSipkyHore(state) {
        if (state.selectedItem > 0) {
            state.selectedItem--;
        } else if (state.selectedItem <= 0) {
            state.selectedItem = (state.bestitems.length - 1 + state.cookiesitems.length)
        }

        return state.selectedItem;
    }

    /**
     * Tato metoda sa vykona pri stlaceni klavesy ENTER
     * @param {*} state 
     */
    static stlacenieEnteru(state) {
        // enter
        if (state.selectedItem < 0) {
            window.open(state.items.konfig[0].webovaSluzbaGoPrefix + state.searchWord + state.items.konfig[0].webovaSluzbaGoPostfix,'_top');
         } else {
            this.zapisCookiesAOtvorStranku(state);
         }
    }

    /**
     * Tato metoda rozhodne, ci sa jedna o polozku z cookies alebo o polozku z bestitems
     * taktiez rozhodne o aktualnej pozicii, aspon pri bestitems vybere
     * @param {*} state 
     */
    static stlacenieEnteru(state) {
        console.log(state.cookiesitems,state.bestitems, state.selectedItem)
        if(state.selectedItem < state.cookiesitems.length) {
            Cookiesky.zapisCookies(state.items.konfig[0].pouzivatCookies, state.cookiesitems[state.selectedItem].idPolozky)
            window.open(state.cookiesitems[state.selectedItem].vysledneUrl,'_top');
        } else {
            let odpocitanaVybranaPolozka = state.selectedItem - state.cookiesitems.length;

            Cookiesky.zapisCookies(state.items.konfig[0].pouzivatCookies, state.bestitems[odpocitanaVybranaPolozka].idPolozky)
            window.open(state.bestitems[odpocitanaVybranaPolozka].vysledneUrl,'_top');
        }
    }
}
